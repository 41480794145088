@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Circular";
  src: url("./assets/font/CircularStd-Book.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: Circular;
}

/* backgrounds */
.home-bg {
  width: 100%;
  background: url("./assets/bg/hero-bg.png");
  background-size: cover;
}

.page-bg {
  width: 100%;
  background: url("./assets/bg/sub-bg.png");
  background-size: cover;
}

.overlay-bg {
  background: linear-gradient(
    180deg,
    rgba(70, 56, 31, 0.3) 0%,
    rgba(49, 34, 9, 0.8775) 82.5%,
    #2c1d05 100%
  );
}

/* scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
